<template>
  <div>
    <v-skeleton-loader
      :loading="loadingTable"
      :transition="'fade-transition'"
      height="500"
      type="table">
      <v-data-table
        :headers="getHeaders(typesHeader)"
        :items="data"
        >

        <template slot="body.prepend">
          <tr>
            <th :colspan="headers.length">Revendedor: {{ data.length ? data[0].entity_cod : ''}}</th>
          </tr>
        </template>

        <template v-slot:item.date="{ item }">
          {{ dateFormat(item.date) }}
        </template>

        <template v-slot:item.proposta="{ item }">
        <v-btn
          style="min-width: 100px;"
          elevation="1"
          color="#FFF"
          plain
          raised
          small
          tile
          @click="opemDadosJogos(item)" v-if="maiorZero(item.proposta)">
         {{ item.proposta | currency}}
        </v-btn>

        <span v-else>{{ item.proposta | currency}}</span>
        </template>

        <template v-slot:item.comissao="{ item }">
          {{ item.comissao | currency }}
        </template>

        <template v-slot:item.saldo="{ item }">
          {{ item.saldo | currency }}
        </template>

        <template v-slot:item.premio="{ item }">
          <v-btn
            style="min-width: 100px;"
            elevation="1"
            color="#FFF"
            plain
            raised
            small
            tile
            @click="opemDadosJogosWinners(item)" v-if="maiorZero(item.premio)">
            {{ item.premio | currency }}
          </v-btn>

          <span v-else>{{ item.premio | currency }}</span>
        </template>

        <template v-slot:item.caixa="{ item }">
          <v-btn
            style="min-width: 100px;"
            elevation="1"
            color="#FFF"
            plain
            raised
            small
            tile
            @click="opemDadosCaixa(item)">
            {{ item.caixa | currency }}
          </v-btn>
          
          <!-- <span v-else>{{ item.caixa | currency }}</span> -->
        </template>

        <template v-slot:item.pagto="{ item }">
          <v-btn
            style="min-width: 100px;"
            elevation="1"
            color="#FFF"
            plain
            raised
            small
            tile
            @click="opemDadosPgto(item)">
            {{ item.pagto | currency }}
          </v-btn>

          <!-- <span v-else>{{ item.pagto | currency }}</span> -->
        </template>

        <template v-slot:item.deve_atual="{ item }">
          {{ item.deve_atual | currency }}
        </template>

        <!-- <template v-slot:item.owe="{ item }">
          {{ item.totais.owe | currency }}
        </template> -->

        <template slot="body.append">
          <tr>
            <th>Total</th>
            <th>{{ totaisGerais ? totaisGerais.proposta : '0,00' | currency }}</th>
            <th>{{ totaisGerais ? totaisGerais.comissao : '0,00' | currency }}</th>
            <th>{{ totaisGerais ? totaisGerais.saldo : '0,00' | currency }}</th>
            <th>{{ totaisGerais ? totaisGerais.premio : '0,00' | currency }}</th>
            <th>{{ totaisGerais ? totaisGerais.caixa : '0,00' | currency }}</th>
            <th>{{ totaisGerais ? totaisGerais.pagto : '0,00' | currency }}</th>
            <th>{{ totaisGerais ? totaisGerais.deve_atual : '0,00' | currency }}</th>
            <!-- <th>teste</th> -->
          </tr>
        </template>
      </v-data-table>
    </v-skeleton-loader>

    <modalTransmissoes />
    <modalJogosWinners />
    <modalCaixa />
    <modalPgto />
  </div>
</template>

<script>
import Events from '@/core/service/events'
import moment from 'moment'

export default {
  name: 'TabelaHistoricoVendas',
  props: {
    data: {
      type: [ Array, Object ],
      default: () => []
    },

    hasMonthOrDate: {
      type: Number,
      default: 1
    },

    typesHeader: {
      type: Array,
      default: () => []
    }
  },
  // mixins: [listagemTables],
  components: {
    modalTransmissoes: () => import('./components/modalTransmissoes'),
    modalJogosWinners: () => import('./components/modalJogosWinners'),
    modalCaixa: () => import('./components/modalCaixa'),
    modalPgto: () => import('./components/modalPgto'),
  },
  data: () => ({
    headers: [
      { align: 'start', class: 'table-header', text: 'Data mov.', value: 'date' },
      { align: 'start', class: 'table-header', text: 'Vendas', value: 'proposta' },
      { align: 'start', class: 'table-header', text: '(-) Comissão', value: 'comissao' },
      { align: 'start', class: 'table-header', text: 'Líquido', value: 'saldo' },
      { align: 'start', class: 'table-header', text: 'Prêmios', value: 'premio' },
      { align: 'start', class: 'table-header', text: 'Caixa', value: 'caixa' },
      { align: 'start', class: 'table-header', text: 'PGTO', value: 'pagto' },
      { align: 'start', class: 'table-header', text: '=DEVE', value: 'deve_atual' },
      // { align: 'start', class: 'table-header', text: '=DEVE', value: 'owe' }
    ],
    loading: false,
    loadingTable: false,
  }),

  computed: {
    entityRequest () {
      return this.data.entity
    },

    totaisGerais () {
      return  {
          caixa: this.getTotais(this.data, 'caixa'),
          comissao: this.getTotais(this.data, 'comissao'),
          deve_atual: this.getTotais(this.data, 'deve_atual'),
          pagto: this.getTotais(this.data, 'pagto'),
          premio: this.getTotais(this.data, 'premio'),
          premio_pgto_central: this.getTotais(this.data, 'premio_pgto_central'),
          proposta: this.getTotais(this.data, 'proposta'),
          saldo: this.getTotais(this.data, 'saldo'),
      }
    }
  },

  methods: {
    opemDadosJogos(val) {
      Events.$emit('modal::historicoVendas::transmissoes', { data: val.date, entity: val, hasMonthOrDate: this.hasMonthOrDate })
    },

    opemDadosJogosWinners(val) {
      Events.$emit('modal::historicoVendas::jogosWinners', { data: val.date, entity: val, hasMonthOrDate: this.hasMonthOrDate })
    },

    opemDadosCaixa(val) {
      Events.$emit('modal::historicoVendas::caixa', { data: val.date, entity: val, hasMonthOrDate: this.hasMonthOrDate })
    },

    opemDadosPgto(val) {
      Events.$emit('modal::historicoVendas::pgto', { data: val.date, entity: val, hasMonthOrDate: this.hasMonthOrDate })
    },

    getTotais (arr, type) {
      return arr.reduce((acc, cur) => {
        return acc + parseFloat(cur[type])
      }, 0)
    },

    getHeaders (typesHeader) {
      return this.headers.filter(item => {
        return !typesHeader.includes(item.value)
      })
    },

    dateFormat (date) {
      if (this.hasMonthOrDate === 2) {
        return moment(date).format('MM/YYYY')
      }

      return moment(date).format('DD/MM/YYYY')
    },

    maiorZero (value) {
      return parseFloat(value)!== 0
    }
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
</style>