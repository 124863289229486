var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-skeleton-loader',{attrs:{"loading":_vm.loadingTable,"transition":'fade-transition',"height":"500","type":"table"}},[_c('v-data-table',{attrs:{"headers":_vm.getHeaders(_vm.typesHeader),"items":_vm.data},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.date))+" ")]}},{key:"item.proposta",fn:function(ref){
var item = ref.item;
return [(_vm.maiorZero(item.proposta))?_c('v-btn',{staticStyle:{"min-width":"100px"},attrs:{"elevation":"1","color":"#FFF","plain":"","raised":"","small":"","tile":""},on:{"click":function($event){return _vm.opemDadosJogos(item)}}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.proposta))+" ")]):_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.proposta)))])]}},{key:"item.comissao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.comissao))+" ")]}},{key:"item.saldo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.saldo))+" ")]}},{key:"item.premio",fn:function(ref){
var item = ref.item;
return [(_vm.maiorZero(item.premio))?_c('v-btn',{staticStyle:{"min-width":"100px"},attrs:{"elevation":"1","color":"#FFF","plain":"","raised":"","small":"","tile":""},on:{"click":function($event){return _vm.opemDadosJogosWinners(item)}}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.premio))+" ")]):_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.premio)))])]}},{key:"item.caixa",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"min-width":"100px"},attrs:{"elevation":"1","color":"#FFF","plain":"","raised":"","small":"","tile":""},on:{"click":function($event){return _vm.opemDadosCaixa(item)}}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.caixa))+" ")])]}},{key:"item.pagto",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"min-width":"100px"},attrs:{"elevation":"1","color":"#FFF","plain":"","raised":"","small":"","tile":""},on:{"click":function($event){return _vm.opemDadosPgto(item)}}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.pagto))+" ")])]}},{key:"item.deve_atual",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.deve_atual))+" ")]}}])},[_c('template',{slot:"body.prepend"},[_c('tr',[_c('th',{attrs:{"colspan":_vm.headers.length}},[_vm._v("Revendedor: "+_vm._s(_vm.data.length ? _vm.data[0].entity_cod : ''))])])]),_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("Total")]),_c('th',[_vm._v(_vm._s(_vm._f("currency")(_vm.totaisGerais ? _vm.totaisGerais.proposta : '0,00')))]),_c('th',[_vm._v(_vm._s(_vm._f("currency")(_vm.totaisGerais ? _vm.totaisGerais.comissao : '0,00')))]),_c('th',[_vm._v(_vm._s(_vm._f("currency")(_vm.totaisGerais ? _vm.totaisGerais.saldo : '0,00')))]),_c('th',[_vm._v(_vm._s(_vm._f("currency")(_vm.totaisGerais ? _vm.totaisGerais.premio : '0,00')))]),_c('th',[_vm._v(_vm._s(_vm._f("currency")(_vm.totaisGerais ? _vm.totaisGerais.caixa : '0,00')))]),_c('th',[_vm._v(_vm._s(_vm._f("currency")(_vm.totaisGerais ? _vm.totaisGerais.pagto : '0,00')))]),_c('th',[_vm._v(_vm._s(_vm._f("currency")(_vm.totaisGerais ? _vm.totaisGerais.deve_atual : '0,00')))])])])],2)],1),_c('modalTransmissoes'),_c('modalJogosWinners'),_c('modalCaixa'),_c('modalPgto')],1)}
var staticRenderFns = []

export { render, staticRenderFns }